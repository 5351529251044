import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Partners from '../components/Partners';
import DownloadRegistration from '../components/DownloadRegistrations';



function DownloadReg() {
  return (
    <>
      <Navbar />
        <DownloadRegistration/>
      <Footer />
     </>
  );
}

export default DownloadReg;