import React from 'react';

function WhoToAttend() {
  return (
    <section className="section why-us" data-section="section2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <div className="section-heading">
              <h2>Who Will Attend?</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-4 text-center">
            <div className="align-items-center text-left text-white">
              <ul className="checkmark text-white"> {/* Apply text-white class for white color */}
                <li>Executives</li>
                <li>Regulators</li>
                <li>Heads of ICT</li>
                <li>Risk managers</li>
                <li>Fintechs</li>
                <li>Heads of Operations</li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="align-items-center text-left">
              <ul className="checkmark text-white"> {/* Apply text-white class for white color */}
                <li>Fraud Experts</li>
                <li>Payments System Specialists</li>
                <li>Small and Medium Businesses</li>
                <li>Heads of Digital payments</li>
                <li>Mobile Payments Experts</li>
                
              </ul>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="align-items-center text-left">
              <ul className="checkmark text-white"> {/* Apply text-white class for white color */}
                <li>Merchants from different sectors</li>
                <li>Cyber Security Experts</li>
                <li>Payments System Specialists</li>
                <li>Small and Medium Businesses</li>
                <li>Heads of Digital payments</li>
                <li>Card Manufacturers and Suppliers</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhoToAttend;
