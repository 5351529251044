import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Index from './pages/Index';
import Agenda from './pages/Agenda';
import Partner from './pages/Partners';
import Speaker from './pages/Speakers';
import Feedbacks from './pages/Feedback';
import Register from './pages/Registration';
import DownloadReg from './pages/DownloadReg';
import DownloadFeeds from './pages/DownloadFeeds';

function App() {
  const sectionIds = ['#about', '#what-we-do', '#team', '#products', '#contact'];

  return (
    <Router>
      <Routes>
      <Route path="/" element={<Index />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/partners" element={<Partner />} />
        <Route path="/speakers" element={<Speaker />} />
        <Route path="/feedback" element={<Feedbacks />} />
        <Route path="/registration" element={<Register />} />
        <Route path="/download_registration" element={<DownloadReg />} />
        <Route path="/download_feedback" element={<DownloadFeeds />} />
      </Routes>
     
    </Router>
  );
}

export default App;