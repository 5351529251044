import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Partners from '../components/Partners';
import DownloadRegistration from '../components/DownloadRegistrations';
import DownloadFeedbacks from '../components/DownloadFeedbacks';



function DownloadFeeds() {
  return (
    <>
      <Navbar />
        <DownloadFeedbacks/>
      <Footer />
     </>
  );
}

export default DownloadFeeds;