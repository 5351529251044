import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import StartFirebase from './config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DownloadFeedbacks() {

    const db = StartFirebase();
    const [registrations, setRegistrations] = useState([]);
    
    useEffect(() => {
        const fetchFeedbacks = async () => {
            try {
                const registrationCollection = collection(db, 'feedback');
                const querySnapshot = await getDocs(registrationCollection);
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push(doc.data());
                });
                setRegistrations(data);
            } catch (error) {
                console.error('Error fetching feedbacks:', error);
            }
        };
        fetchFeedbacks();
    }, [db]);

    
    const downloadCSV = () => {
        const fieldsOrder = [
            'firstName',
            'lastName',
            'phoneNumber',
            'email',
            'message',
            
        ];
    
        const csvContent = "data:text/csv;charset=utf-8," +
            fieldsOrder.join(",") + "\n" +
            registrations.map(registration =>
                fieldsOrder.map(field => registration[field]).join(",")
            ).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "feedbacks_data.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
    };
    

    return (
        <>
            <ToastContainer />
            <section className="heading-page5 header-text" id="top">
                <div className="container">
                    <div className="row"></div>
                </div>
            </section>
            <br />
            <div className="container" style={{ display: 'flex', justifyContent: 'center' }}>
                <button className="main-button2" onClick={downloadCSV}>Download Feedback </button>
            </div>

        </>
    );
}
