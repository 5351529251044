import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import StartFirebase from './config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Registration() {

    const db = StartFirebase();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    idPassportNumber: '',
    mobileNumber: '',
    email: '',
    nationality: '',
    organisation: '',
    designation: '',
    golfShirtSize: '',
    day1Activity: '',
    day2Activity: '',
    dietaryRestrictions: '',
    arrivalDate: '',
    arrivalTime: '',
    checkoutDate: '',
    checkoutTime: ''
  });

    const triggerToast = () => {
        toast("Registration Successful");
    }
    const handleChange = (e) => {
        const { name, id, value } = e.target;
    
        if (name === 'gender') {
            setFormData({
                ...formData,
                gender: value
            });
        } else if (name === 'golfShirtSize') {
            setFormData({
                ...formData,
                golfShirtSize: value
            });
        } else if (name === 'day1Activity') {
            setFormData({
                ...formData,
                day1Activity: value
            });
        } else if (name === 'day2Activity') {
            setFormData({
                ...formData,
                day2Activity: value
            });
        } 
        else {
            setFormData({
                ...formData,
                [id]: value
            });
        }
    };
    
    

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');

    try {
      const registrationCollection = collection(db, 'registration');
      await addDoc(registrationCollection, formData);
      triggerToast()

      setFormData({
        firstName: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        idPassportNumber: '',
        mobileNumber: '',
        email: '',
        nationality: '',
        organisation: '',
        designation: '',
        golfShirtSize: '',
        day1Activity: '',
        day2Activity: '',
        dietaryRestrictions: '',
        arrivalDate: '',
        arrivalTime: '',
        checkoutDate: '',
        checkoutTime: ''
      });
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  return (
    <>
    <ToastContainer />
      <section className="heading-page5 header-text" id="top">
        <div className="container">
          <div className="row"></div>
        </div>
      </section>
      <br />
      <div className="container">
        <form onSubmit={handleSubmit}>
          {/* First Name */}
          <div className="form-group row">
            <label htmlFor="firstName" className="col-sm-2 col-form-label">First Name</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
            </div>
          </div>
          {/* Last Name */}
          <div className="form-group row">
            <label htmlFor="lastName" className="col-sm-2 col-form-label">Last Name</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
            </div>
          </div>
          {/* Gender */}
          <fieldset className="form-group">
            <div className="row">
                <legend className="col-form-label col-sm-2 pt-0">Gender</legend>
                <div className="col-sm-10">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="gender" id="male" value="male" onChange={handleChange} checked={formData.gender === 'male'} />
                        <label className="form-check-label" htmlFor="male">
                            Male
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="gender" id="female" value="female" onChange={handleChange} checked={formData.gender === 'female'} />
                        <label className="form-check-label" htmlFor="female">
                            Female
                        </label>
                    </div>
                </div>
            </div>
        </fieldset>

          {/* Date Of Birth */}
          <div className="form-group row">
            <label htmlFor="dateOfBirth" className="col-sm-2 col-form-label">Date Of Birth</label>
            <div className="col-sm-10">
              <input type="date" className="form-control" id="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} />
            </div>
          </div>
          {/* ID / Passport Number */}
          <div className="form-group row">
            <label htmlFor="idPassportNumber" className="col-sm-2 col-form-label">ID / Passport Number</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="idPassportNumber" placeholder="ID or Passport Number" value={formData.idPassportNumber} onChange={handleChange} />
            </div>
          </div>
          {/* Mobile Number */}
          <div className="form-group row">
            <label htmlFor="mobileNumber" className="col-sm-2 col-form-label">Mobile Number</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="mobileNumber" placeholder="Mobile Number" value={formData.mobileNumber} onChange={handleChange} />
            </div>
          </div>
          {/* Email */}
          <div className="form-group row">
            <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
            <div className="col-sm-10">
              <input type="email" className="form-control" id="email" placeholder="Email Address" value={formData.email} onChange={handleChange} />
            </div>
          </div>
          {/* Nationality */}
          <div className="form-group row">
            <label htmlFor="nationality" className="col-sm-2 col-form-label">Nationality</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="nationality" placeholder="Nationality" value={formData.nationality} onChange={handleChange} />
            </div>
          </div>
          {/* Organisation */}
          <div className="form-group row">
            <label htmlFor="organisation" className="col-sm-2 col-form-label">Organisation</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="organisation" placeholder="Organisation" value={formData.organisation} onChange={handleChange} />
            </div>
          </div>
          {/* Designation */}
          <div className="form-group row">
            <label htmlFor="designation" className="col-sm-2 col-form-label">Designation</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="designation" placeholder="Designation" value={formData.designation} onChange={handleChange} />
            </div>
          </div>
          {/* Golf Shirt & Jacket Size */}
          <fieldset className="form-group">
            <div className="row">
              <legend className="col-form-label col-sm-2 pt-0">Golf Shirt</legend>
              <div className="col-sm-10">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="golfShirtSize" id="small" value="small" onChange={handleChange} checked={formData.golfShirtSize === 'small'} />
                  <label className="form-check-label" htmlFor="small">
                    Small
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="golfShirtSize" id="medium" value="medium" onChange={handleChange} checked={formData.golfShirtSize === 'medium'} />
                  <label className="form-check-label" htmlFor="medium">
                    Medium
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="golfShirtSize" id="medium" value="large" onChange={handleChange} checked={formData.golfShirtSize === 'large'} />
                  <label className="form-check-label" htmlFor="medium">
                    Large
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="golfShirtSize" id="medium" value="XL" onChange={handleChange} checked={formData.golfShirtSize === 'XL'} />
                  <label className="form-check-label" htmlFor="medium">
                    XL
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="golfShirtSize" id="medium" value="XXL" onChange={handleChange} checked={formData.golfShirtSize === 'XXL'} />
                  <label className="form-check-label" htmlFor="medium">
                    XXL
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="golfShirtSize" id="medium" value="XXXL" onChange={handleChange} checked={formData.golfShirtSize === 'XXXL'}  />
                  <label className="form-check-label" htmlFor="medium">
                    XXXL
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
          {/* Day 1 Activities (Optional) */}
          <fieldset className="form-group">
            <div className="row">
              <legend className="col-form-label col-sm-2 pt-0">Day 1 Activities (Optional)</legend>
              <div className="col-sm-10">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="day1Activity" id="golf" value="Golf (1230hrs to 1700hrs)" onChange={handleChange} checked={formData.day1Activity === 'Golf (1230hrs to 1700hrs)'} />
                  <label className="form-check-label" htmlFor="golf">
                    Golf (1230hrs to 1700hrs)
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="day1Activity" id="fallsTour" value="Falls Tour (1415hrs to 1630hrs)" onChange={handleChange} checked={formData.day1Activity === 'Falls Tour (1415hrs to 1630hrs)'} />
                  <label className="form-check-label" htmlFor="fallsTour">
                    Falls Tour (1415hrs to 1630hrs)
                  </label>
                </div>
                {/* Add more options for day 1 activities */}
              </div>
            </div>
          </fieldset>
          {/* Day 2 Activity (Compulsory) */}
          <fieldset className="form-group">
            <div className="row">
              <legend className="col-form-label col-sm-2 pt-0">Day 2 Activity (Compulsory)</legend>
              <div className="col-sm-10">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="day2Activity" id="dinnerCruise" value="Dinner Cruise (1720hrs to 2030hrs)" onChange={handleChange}  checked={formData.day1Activity === 'Dinner Cruise (1720hrs to 2030hrs)'} />
                  <label className="form-check-label" htmlFor="dinnerCruise">
                    Dinner Cruise (1720hrs to 2030hrs)
                  </label>
                </div>
                {/* Add more options for day 2 activity */}
              </div>
            </div>
          </fieldset>
          {/* Dietary Restrictions */}
          <div className="form-group row">
            <label htmlFor="dietaryRestrictions" className="col-sm-2 col-form-label">Dietary Restrictions</label>
            <div className="col-sm-10">
              <textarea className="form-control" id="dietaryRestrictions" placeholder="Dietary Restrictions" value={formData.dietaryRestrictions} onChange={handleChange} />
            </div>
          </div>
          {/* International Delegates Travel Arrangements */}
          <div className="form-group row">
            <label htmlFor="internationalTravelArrangements" className="col-sm-2 col-form-label">International Delegates Travel Arrangements</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="internationalTravelArrangements" placeholder="The section below is for International Delegates Travel Arrangements Only"disabled/>
            </div>
          </div>
          {/* Date of arrival to Victoria Falls */}
          <div className="form-group row">
            <label htmlFor="arrivalDate" className="col-sm-2 col-form-label">Date of arrival to Victoria Falls</label>
            <div className="col-sm-10">
              <input type="date" className="form-control" id="arrivalDate" value={formData.arrivalDate} onChange={handleChange} />
            </div>
          </div>
          {/* Time of arrival */}
          <div className="form-group row">
            <label htmlFor="arrivalTime" className="col-sm-2 col-form-label">Time of arrival</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="arrivalTime" placeholder="0000" value={formData.arrivalTime} onChange={handleChange} />
            </div>
          </div>
          {/* Date of checkout */}
          <div className="form-group row">
            <label htmlFor="checkoutDate" className="col-sm-2 col-form-label">Date of checkout</label>
            <div className="col-sm-10">
              <input type="date" className="form-control" id="checkoutDate" value={formData.checkoutDate} onChange={handleChange} />
            </div>
          </div>
          {/* Time of check out */}
          <div className="form-group row">
            <label htmlFor="checkoutTime" className="col-sm-2 col-form-label">Time of check out</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" id="checkoutTime" placeholder="0000" value={formData.checkoutTime} onChange={handleChange} />
            </div>
          </div>
          <p>International delegates are strongly encouraged to land at Victoria Falls Airport</p>
          <div className="form-group row">
            <div className="col-sm-10">
              <button type="submit" className="main-button2">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
