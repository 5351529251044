import React from 'react'

export default function About() {
  return (
    <section class="section video" data-section="section5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 align-self-center">
          <div class="left-content">
            <h4> <em>THRIVING AND SHAPING THE FUTURE IN AN ERA OF CONTINUOUS CHANGE.</em></h4>
            <p className='paragraphs'>Let’s get together again for the ultimate experience of igniting and inspiring the future of payments. 
              We are excited to present our flagship event, the Zimswitch Payments Conference to be held from the 21st to the 24th of July 2024 at Elephant Hills Hotel Victoria Falls. 
              Converging the worlds of financial services; banks, mobile money operators, fintechs, merchants, and the payments service industry at large, the conference brings in local and international thought leaders, market disruptors, and payment industry experts to provide real practical and actionable insights into the state of payment systems. Their expertise will highlight pivotal industry developments and guide thought leadership in our rapidly evolving world. Be part of this enriching opportunity and JOIN THE CONVERSATION.</p>
          </div>
        </div>
        <div class="col-md-6">
          <article class="video-item">
            {/* <div class="video-caption">
              <h4>Power HTML Template</h4>
            </div> */}
            <figure>
             <img src="assets/images/CONFERENCE 2019 1.jpg"/>
            </figure>
          </article>
        </div>
      </div>
    </div>
  </section>
  )
}
