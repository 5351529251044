import React from 'react';

export default function Partners() {
  return (
    <>
      <section className="heading-page3 header-text" id="top">
        <div className="container">
          <div className="row"></div>
        </div>
      </section>
      <br />

      <div className="container py-5">
        <header className="text-center mb-5">
          <h1 className="display-4 font-weight-bold">Partners</h1>
        </header>
        <div className="row pb-5 mb-4">
        <div className="col-lg-6 col-md-6 mb-4 mb-lg-0 d-flex">
            <div className="card rounded shadow-sm border-0 flex-fill">
              <div className="card-body p-4">
                <img
                  src="assets/images/partner1.jpeg"
                  alt=""
                  className="img-fluid d-block mx-auto mb-3"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 mb-4 mb-lg-0 d-flex">
            <div className="card rounded shadow-sm border-0 flex-fill">
              <div className="card-body p-4">
                <img
                  src="assets/images/ACI Horizontal + Tagline.png "
                  alt=""
                  className="img-fluid d-block mx-auto mb-3"
                />
              </div>
            </div>
          </div>

          {/* <div className="col-lg-4 col-md-6 mb-4 mb-lg-0 d-flex">
            <div className="card rounded shadow-sm border-0 flex-fill">
              <div className="card-body p-4">
                <img
                  src="assets/images/eSolutions_logo_with_no_background.png"
                  alt=""
                  className="img-fluid d-block mx-auto mb-3"
                />
              </div>
            </div>
          </div> */}

        </div>

        <div className="row pb-5 mb-4">
        <div className="col-lg-6 col-md-6 mb-4 mb-lg-0 d-flex">
            <div className="card rounded shadow-sm border-0 flex-fill">
              <div className="card-body p-4">
                <img
                  src="assets/images/securi centrix.png"
                  alt=""
                  className="img-fluid d-block mx-auto mb-3"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 mb-4 mb-lg-0 d-flex">
            <div className="card rounded shadow-sm border-0 flex-fill">
              <div className="card-body p-4">
                <img
                  src="assets/images/Mastercard-Logo.wine.png"
                  alt=""
                  className="img-fluid d-block mx-auto mb-3"
                />
              </div>
            </div>
          </div>

          {/* <div className="col-lg-4 col-md-6 mb-4 mb-lg-0 d-flex">
            <div className="card rounded shadow-sm border-0 flex-fill">
              <div className="card-body p-4">
                <img
                  src="assets/images/Tendo Logo Blue BG.jpg"
                  alt=""
                  className="img-fluid d-block mx-auto mb-3"
                />
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className="row pb-5 mb-4">
        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0 d-flex">
            <div className="card rounded shadow-sm border-0 flex-fill">
              <div className="card-body p-4">
                <img
                  src="assets/images/image001.png"
                  alt=""
                  className="img-fluid d-block mx-auto mb-3"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 mb-lg-0 d-flex">
            <div className="card rounded shadow-sm border-0 flex-fill">
              <div className="card-body p-4">
                <img
                  src="assets/images/paynow.png"
                  alt=""
                  className="img-fluid d-block mx-auto mb-3"
                />
              </div>
            </div>
          </div>

        </div> */}
      </div>
      <a className='members-link' href="https://www.zimswitch.co.zw/members-and-partners">
        <div className="">     
            <h2 className='display-4 font-weight-bold'>Members</h2> 
        </div>
      </a>

      
    </>
  );
}
