import React from 'react';
import { FaDownload } from 'react-icons/fa';

export default function Agenda() {
    const handleDownload = () => {
        const pdfUrl = '/assets/images/Zimswitch-Payments-Conference-Prospectus.pdf';
        window.open(pdfUrl, '_blank');
    };

    return (
        <>
            <section className="heading-page header-text" id="top">
                <div className="container">
                    <div className="row"></div>
                </div>
            </section>
            <br />
            <div className="main-button text-center">
                <div className="scroll-to-section">
                    <a href="#section2" onClick={handleDownload}>
                        <FaDownload /> Download Conference Prospectus
                    </a>
                </div>
            </div>
        </>
    );
}
