import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Registration from '../components/Registration';



function Register() {
  return (
    <>
      <Navbar />
        <Registration/>
      <Footer />
     </>
  );
}

export default Register;