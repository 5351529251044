import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import StartFirebase from './config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Feedback() {
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const db = StartFirebase();
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };
  const triggerToast = () => {
    toast("Feedback Recorded Successfully");
}
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');

    try {
      const feedbacksCollection = collection(db, 'feedback');
      await addDoc(feedbacksCollection, formData);
      console.log('Data posted successfully', formData);
      triggerToast()
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  return (
    <>
    <ToastContainer />
      <section className="heading-page4 header-text" id="top">
        <div className="container">
          <div className="row"></div>
        </div>
      </section>
      <br />
      <div className="container">
        <form className='feedback' onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="firstName">First Name</label>
              <input type="text" className="form-control" id="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="lastName">Last Name</label>
              <input type="text" className="form-control" id="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="email">Email Address</label>
              <input type="email" className="form-control" id="email" placeholder="Email Address" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input type="text" className="form-control" id="phoneNumber" placeholder="+263777777777" value={formData.phoneNumber} onChange={handleChange} required />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea className="form-control" id="message" placeholder="Message" value={formData.message} onChange={handleChange} />
          </div>
          <button type="submit" className="main-button2">Submit</button>
        </form>
      </div>
    </>
  );
}
