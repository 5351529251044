import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Partners from '../components/Partners';
import Feedback from '../components/Feedback';



function Feedbacks() {
  return (
    <>
      <Navbar />
        <Feedback/>
      <Footer />
     </>
  );
}

export default Feedbacks;