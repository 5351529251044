import React, { useState } from 'react';

export default function PrevVideos() {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = (videoId) => {
    const video = document.getElementById(videoId);
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <section className="section agenda" data-section="section5">
      <div className="container">
        <div className="section-heading2">
          <h2>PREVIOUS CONFERENCES VIDEOS</h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <article className="agenda-item">
              <figure>
                <div className="col-md-12">
                  <video
                    id="videoPlayer1"
                    controls
                    onClick={() => togglePlay('videoPlayer1')}
                    style={{ cursor: 'pointer', width: '100%' }}
                  >
                    <source src="https://dl49j1ypakcw5.cloudfront.net/Two_2nd.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </figure>
            </article>
          </div>
          <div className="col-md-6">
            <article className="agenda-item">
              <figure>
                <div className="col-md-12">
                  <video
                    id="videoPlayer2"
                    controls
                    onClick={() => togglePlay('videoPlayer2')}
                    style={{ cursor: 'pointer', width: '100%' }}
                  >
                    <source src="https://dl49j1ypakcw5.cloudfront.net/Two-3rd.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </figure>
            </article>
          </div>
          <div className="col-md-6">
            <article className="agenda-item">
              <figure>
                <div className="col-md-12">
                  <video
                    id="videoPlayer3"
                    controls
                    onClick={() => togglePlay('videoPlayer3')}
                    style={{ cursor: 'pointer', width: '100%' }}
                  >
                    <source src="https://dl49j1ypakcw5.cloudfront.net/One_3rd.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </figure>
            </article>
          </div>
          <div className="col-md-6">
            <article className="agenda-item">
              <figure>
                <div className="col-md-12">
                  <video
                    id="videoPlayer4"
                    controls
                    onClick={() => togglePlay('videoPlayer4')}
                    style={{ cursor: 'pointer', width: '100%' }}
                  >
                    <source src="https://dl49j1ypakcw5.cloudfront.net/One_2nd.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </figure>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
