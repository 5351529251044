
import { initializeApp } from 'firebase/app';
import { getFirestore} from 'firebase/firestore';
import { getAuth } from "firebase/auth";

function StartFirebase() {
    const firebaseConfig = {
        apiKey: "AIzaSyDtTWBzraLdxFMUIHp-pCH0pC52E5sL7tM",
        authDomain: "zimswitch-807d3.firebaseapp.com",
        projectId: "zimswitch-807d3",
        storageBucket: "zimswitch-807d3.appspot.com",
        messagingSenderId: "496020720714",
        appId: "1:496020720714:web:8eda077daff278a17f6ab3",
        measurementId: "G-21GGF34XN5"
      };

  const app = initializeApp(firebaseConfig);
  return getFirestore(app);
  
}

const firebaseConfig = {
    apiKey: "AIzaSyDtTWBzraLdxFMUIHp-pCH0pC52E5sL7tM",
    authDomain: "zimswitch-807d3.firebaseapp.com",
    projectId: "zimswitch-807d3",
    storageBucket: "zimswitch-807d3.appspot.com",
    messagingSenderId: "496020720714",
    appId: "1:496020720714:web:8eda077daff278a17f6ab3",
    measurementId: "G-21GGF34XN5"
  };

const app = initializeApp(firebaseConfig);
export default StartFirebase;
export const auth = getAuth(app);