import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import StartFirebase from './config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DownloadRegistration() {

    const db = StartFirebase();
    const [registrations, setRegistrations] = useState([]);
    
    useEffect(() => {
        const fetchRegistrations = async () => {
            try {
                const registrationCollection = collection(db, 'registration');
                const querySnapshot = await getDocs(registrationCollection);
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push(doc.data());
                });
                setRegistrations(data);
            } catch (error) {
                console.error('Error fetching registrations:', error);
            }
        };
        fetchRegistrations();
    }, [db]);

    const triggerToast = () => {
        toast("Registration Successful");
    }

    const downloadCSV = () => {
        const fieldsOrder = [
            'firstName',
            'lastName',
            'gender',
            'dateOfBirth',
            'idPassportNumber',
            'mobileNumber',
            'email',
            'nationality',
            'organisation',
            'designation',
            'golfShirtSize',
            'day1Activity',
            'day2Activity',
            'dietaryRestrictions',
            'arrivalDate',
            'arrivalTime',
            'checkoutDate',
            'checkoutTime'
        ];
    
        const csvContent = "data:text/csv;charset=utf-8," +
            fieldsOrder.join(",") + "\n" +
            registrations.map(registration =>
                fieldsOrder.map(field => registration[field]).join(",")
            ).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "registration_data.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
    };
    

    return (
        <>
            <ToastContainer />
            <section className="heading-page5 header-text" id="top">
                <div className="container">
                    <div className="row"></div>
                </div>
            </section>
            <br />
            <div className="container" style={{ display: 'flex', justifyContent: 'center' }}>
                <button className="main-button2" onClick={downloadCSV}>Download Registrations  CSV</button>
            </div>

        </>
    );
}
